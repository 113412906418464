import {Injectable} from '@angular/core'
import {map, switchMap} from 'rxjs/operators'
import {Observable} from 'rxjs'
import {HttpClient, HttpHeaders} from '@angular/common/http'
import {environment} from '../../environments/environment'
import {SECRET} from '../../environments/secret'


/**
 * Describes the latest valid interest
 */
export interface LatestRiskInterest {
  risk1: number
  risk2: number
  risk3: number
  risk4: number
  interestStartDate: number
  interfaceDate: number | null
}


@Injectable({
  providedIn: 'root'
})
export class DisclaimerService {

  constructor(private httpClient: HttpClient) {
  }

  /**
   * Get the latest valid interest from database
   */
  public getLatestInterest(): Observable<LatestRiskInterest> {
    let headers = new HttpHeaders()
    headers = headers.set('X-Api-Key', SECRET)

    const url = `${environment.rtUrl}/interests/latest/blanco`
    return this.httpClient.get<LatestRiskInterest>(url, {headers})
  }

  /**
   * Get disclaimer and interests from backend.
   */
  public getDisclaimer(p = 60, a = 100000, sF = 300): Observable<{
    riskInterest: LatestRiskInterest
    disclaimer: string
  }> {
    let headers = new HttpHeaders()
    headers = headers.set('Content-Type', 'text/plain').set('X-Api-Key', SECRET)

    return this.getLatestInterest().pipe(
      switchMap((riskInterest: LatestRiskInterest) => {

        const interfaceDate = riskInterest.interfaceDate || riskInterest.interestStartDate

        const url = `${environment.rtUrl}/interests/` +
          `disclaimer?periods=${p}&amount=${a}&interestRate=${riskInterest.risk2 / 100}&startFee=${sF}` +
          `&interestStartDate=${interfaceDate}&service=blanco`

        // Fetch the disclaimer from the API
        return this.httpClient.get(url, {
          headers,
          responseType: 'text'
        }).pipe(
          map((disclaimer: string) => ({riskInterest, disclaimer}))
        )
      })
    )
  }

}
