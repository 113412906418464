import {Component, Inject, OnInit} from '@angular/core'
import {AbstractControl, FormArray, FormControl, FormGroup, Validators} from '@angular/forms'
import {MatDialog} from '@angular/material/dialog'
import {Router} from '@angular/router'
import version from '../../../assets/package.json'
import {APPLICATION_NAME, GenericTypesService, PropertyType} from '../../application/data-types'
import {LOCAL_STORAGE} from '../../application/localstorage.provider'
import {WaitDialogComponent} from '../../common/wait-dialog/wait-dialog.component'
import {DisclaimerService, LatestRiskInterest} from '../../services/disclaimer.service'
import {LoanService} from '../../services/loan.service'
import {checkboxValidator} from '../directives/checkbox/CheckboxValidator'
import {livingValidator} from '../directives/living/LivingValidator'
import {SafeSignDialogComponent} from './safe-sign-dialog/safe-sign-dialog.component'

export interface ISuperFast {
  loan?: boolean
  income?: boolean
  occupation?: boolean
  coSignedLoan?: boolean
  additionalCosts?: boolean
}

@Component({
  selector: 'spb-blancolan',
  templateUrl: './blancolan.component.html',
  styleUrls: ['./blancolan.component.scss']
})
export class BlancolanComponent implements OnInit {
  /**
   * The disclaimer, is shown in template and we get it from RT
   */
  public rtData: { disclaimer: string; riskInterest: LatestRiskInterest } = {disclaimer: '', riskInterest: {} as any}

  /**
   * This is needed in order to use the enum inside the template?!
   * There is no end to this non-sense... :-p
   */
  public BOSTADSRATT = PropertyType.BOSTADSRATT
  public HYRESRATT = PropertyType.HYRESRATT
  public VILLA = PropertyType.VILLA

  public propertyTypes = GenericTypesService.PropertyTypes

  /**
   * Extend old property types with a no option
   */
  public oldPropertyTypes = [
    {
      name: 'Nej',
      type: PropertyType.NONE
    }
  ].concat(this.propertyTypes)

  public occupationTypes = GenericTypesService.OccupationTypes

  /**
   * Additional loans cannot have bostad.
   */
  public additionalLoanTypes = GenericTypesService.LoanTypes
    .filter(filter => filter.type === 1 || filter.type === 2)

  /**
   * Selection range for how old a child is. Age 0 must be 0.1 to work (and then rounded in template).
   */
  public ages: number[] = [0.1, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17]

  public errorMessage = ''

  // The monthly cost of the blanco
  public monthlyCost: number | null = null

  /**
   * Section #1 A form for the loan
   */
  public loanForm: FormGroup = new FormGroup({
    amount: new FormControl('', [Validators.required, Validators.min(30 * 1000), Validators.max(350 * 1000)]),
    duration: new FormControl('', Validators.required),
    purposes: new FormGroup([
      new FormControl(false),
      new FormControl(false),
      new FormControl(false),
      new FormControl(false),
      new FormControl(false)
    ], checkboxValidator)
  })

  public purposesArray: { key: string, control: AbstractControl<any, any> }[] = []

  /**
   * Section #2 contact information
   */
  public contactForm: FormGroup = new FormGroup({
    coApplicant: new FormControl(false),
    applicantsAreSpouses: new FormControl(false),

    contactData: new FormArray([
      new FormGroup({
        personNummer: new FormControl('', {
          validators: [Validators.required, Validators.minLength(10), Validators.maxLength(13)]
        }),
        name: new FormControl('', Validators.required),
        email: new FormControl('', [Validators.required, Validators.email]),
        phone: new FormControl('',
          {
            validators: [Validators.required, Validators.pattern(/^[\d\s+()-]{8,20}$/)],
            updateOn: 'blur'
          })
      })
    ]),
    numberOfChildren: new FormControl(null),
    children: new FormArray<any>([])
  })

  private makeChildrenArray(value: number) {
    const childrenFormArray = this.contactForm.get('children') as FormArray
    const currentChildrenCount = childrenFormArray.length

    if (value > currentChildrenCount) {
      // Lägg till fler barn
      for (let i = currentChildrenCount; i < value; i++) {
        const childFormGroup = new FormGroup({
          type: new FormControl('children'),
          age: new FormControl(null, Validators.required) // Startvärde är null och validering krävs
        })
        childrenFormArray.push(childFormGroup)
      }
    } else if (value < currentChildrenCount) {
      // Ta bort överskott av barn
      for (let i = currentChildrenCount - 1; i >= value; i--) {
        childrenFormArray.removeAt(i)
      }
    }
  }

  get childrenFormArray(): FormGroup[] {
    return (this.contactForm.get('children') as FormArray).controls as FormGroup[]
  }

  /**
   * Co Applicant data - We create and save it so that
   * we seamlessly can add and remove w/o having to edit
   * the data
   */
  public coApplicantContactData: FormGroup = new FormGroup({
    personNummer: new FormControl('', {
      validators: [Validators.minLength(10), Validators.maxLength(13)]
    }),
    name: new FormControl('', Validators.required),
    email: new FormControl('', [Validators.required]),
    phone: new FormControl('', {
      validators: [Validators.required,
        Validators.minLength(8),
        Validators.maxLength(20),
        Validators.pattern(/^[\d\s+()-]{8,20}$/)],
      updateOn: 'blur'
    })
  }, Validators.required)

  /**
   * Section #3 - A form for income and occupation
   */
  public incomeForm: FormGroup = new FormGroup({
    applicants: new FormArray([
        new FormGroup({
          income: new FormControl('', {
            validators: [Validators.required]
          }),
          occupation: new FormControl('', [Validators.required, Validators.pattern(/[012]/)]),
          employer: new FormControl(''),
          akassa: new FormControl(''),
          spouse: new FormControl(''),
          isSpouse: new FormControl('') // maybe should have Validators.required
        })
      ]
    )
  }, Validators.required)
  /**
   * Section #4 - Living, no point in DRY this?
   */
  public livingForm: FormGroup = new FormGroup({
    primaryLiving: new FormGroup({
      type: new FormControl(null, Validators.required),
      loan: new FormControl(null),
      fee: new FormControl(null),
      rent: new FormControl(null)
    }, livingValidator),
    secondaryLiving: new FormGroup({
      type: new FormControl(null, Validators.required),
      loan: new FormControl(null),
      fee: new FormControl(null),
      rent: new FormControl(null)
    }, livingValidator)
  }, Validators.required)

  /**
   * Section #5 - Loans
   */
  public otherLoansForm: FormGroup = new FormGroup({
    studyLoan: new FormControl('', Validators.max(15 * 1000)), // Make sure they do not add the total debt.
    otherLoans: new FormControl(false),
    loans: new FormArray([]),
    monthlyChildcareCost: new FormControl(0, Validators.max(9999)),
    haveCar: new FormControl('', Validators.required),
    newCar: new FormControl(''),
    carCount: new FormControl(''),
    privateLeaseCar: new FormControl('', Validators.required),
    monthlyPrivateLeaseCarCost: new FormControl(0),
    coSignedLoan: new FormControl('', Validators.required),
    additionalCosts: new FormControl('', Validators.required)
  }, Validators.required)

  /**
   * If the user wants a loan for the purchase of a car, motorcycle or boat...
   */
  public newCar = false

  /**
   * Section #6 The master form from hell,
   */
  public masterForm = new FormGroup<any>({
    loan: this.loanForm,
    contact: this.contactForm,
    income: this.incomeForm,
    living: this.livingForm,
    loans: this.otherLoansForm,
    version: new FormControl(version.version),
    timeStamp: new FormControl(null),
    terms: new FormControl(false, Validators.requiredTrue)
  })

  /**
   * The texts to display for purpose
   */
  public loanPurposes: string[] = [
    'Köp eller renovering av bostad',
    'Köp av bil, MC eller båt',
    'Lösa andra befintliga lån',
    'Resor eller annan konsumtion',
    'Kapitalplaceringar, t ex fonder eller aktier'
  ]

  /**
   * One to 10 years, javascript magic at its best.
   */
  public range: number[] = ([...Array(11).keys()]).splice(1)

  /**
   * Keep track of superfast status
   */
  public superFast = true

  public superMessage: ISuperFast = {}

  /**
   * We have this always so that we can save it if
   * the checkbox is unchecked
   */
  private coApplicantIncomeData = new FormGroup({
    income: new FormControl('', [Validators.required]),
    occupation: new FormControl('', [Validators.required, Validators.pattern(/[012]/)]),
    employer: new FormControl(''),
    akassa: new FormControl(''),
    spouse: new FormControl(''),
    isSpouse: new FormControl('') //maybe should have Validators.required
  })

  /**
   * Holds the error messages
   */
  private errorMessageMap: { [key: number]: string } = {}

  /**
   * Loan service for reasons.
   *
   */
  constructor(
    private dialog: MatDialog,
    private disclaimerService: DisclaimerService,
    private loanService: LoanService,
    private router: Router,
    @Inject(LOCAL_STORAGE) private injectedLocalStorage: Storage
  ) {
    const group: FormGroup = this.loanForm.controls.purposes as FormGroup
    this.loanPurposes.forEach((key: string, index: number) => {
      this.purposesArray.push({key, control: group.controls[index]})
    })

    // Set the errors avoiding eslint errors
    this.errorMessageMap[502] = 'Vi har just nu problem med att göra kreditupplysning, prova igen om en stund.'
    this.errorMessageMap[400] = 'Kan inte starta signering, kontrollera dina uppgifter och försök igen.'
    this.errorMessageMap[406] = 'Någon uppgift du har lämnat verkar inte stämma. Vänligen kontrollera och försök igen.'
    this.errorMessageMap[419] = 'Cannot lock state'
  }

  get applicants(): FormArray {
    return this.incomeForm.get('applicants') as FormArray
  }

  get mainContactPersonnummer(): string | undefined {
    return this.contacts.controls[0].value.personNummer
  }

  get coContactPersonnummer(): string | undefined {
    return this.contacts.controls[1]?.value.personNummer
  }

  /**
   * A couple of getters for readability
   */
  get contacts(): FormArray {
    return this.contactForm.get('contactData') as FormArray
  }

  get hasCoApplicant(): boolean {
    return this.contactForm.controls.coApplicant.value as boolean
  }

  get primaryLiving(): FormGroup {
    return this.livingForm.get('primaryLiving') as FormGroup
  }

  get secondaryLiving(): FormGroup {
    return this.livingForm.get('secondaryLiving') as FormGroup
  }

  get secondaryLivingType(): number {
    const formGroup: FormGroup = this.livingForm.get('secondaryLiving') as FormGroup
    return formGroup.controls.type.value as number
  }

  get additionalLoans(): FormArray {
    return this.otherLoansForm.get('loans') as FormArray
  }

  /**
   * Home brew pluralization
   */
  get plrDinaEra(): string {
    return this.hasCoApplicant ? 'era' : 'dina'
  }

  get plrDinEr(): string {
    return this.hasCoApplicant ? 'er' : 'din'
  }

  get plrDigEr(): string {
    return this.hasCoApplicant ? 'er' : 'dig'
  }

  get plrDuNi(): string {
    return this.hasCoApplicant ? 'Ni' : 'Du'
  }

  get plrJagVi(): string {
    return this.hasCoApplicant ? 'Vi' : 'Jag'
  }

  get plrMinaVara(): string {
    return this.hasCoApplicant ? 'Våra' : 'Mina'
  }

  get plrMinVar(): string {
    return this.hasCoApplicant ? 'Vår' : 'Min'
  }

  public onSpouseSelectionChange(event: boolean, applicant) {
    if (event) {
      // Set spouse as required
      applicant.get('spouse').setValidators(Validators.required)
      applicant.get('spouse').updateValueAndValidity()

    } else {
      // Reset spouse
      applicant.get('spouse').setValue('')
      applicant.get('isSpouse').setValue(false)

      // Remove required from spouse
      applicant.get('spouse').setValidators(null)
      applicant.get('spouse').updateValueAndValidity()
    }
  }

  /**
   * Lots of action here.
   * - Load the application from localstorage if we have one.
   * - Start a monitor for the form:
   * - Check nad set the logged in state
   */
  public ngOnInit(): void {
    this.disclaimerService.getDisclaimer().subscribe({
      next: rtData => {
        this.rtData = rtData
      }
    })
    // The co-applicant status
    this.contactForm.controls.coApplicant.valueChanges.subscribe({
      next: this.checkCoApplicant
    })

    this.otherLoansForm.controls.otherLoans.valueChanges.subscribe((checked: boolean | null) => {
      if (checked === true) {
        this.addLoan()
      } else {
        this.additionalLoans.clear()
      }
    })

    /**
     * Calculates the monthly cost. Called on change of the amount or duration of blanco
     */
    this.loanForm.valueChanges.subscribe(() => {
      if (!this.loanForm.controls.amount.valid || !this.loanForm.controls.duration.valid) {
        this.monthlyCost = null
        return
      }
      const values = this.loanForm.getRawValue()
      const amount = Number.parseInt(values.amount, 10)
      const duration = Number.parseInt(values.duration, 10)
      const repaymentMonthly = Math.ceil((amount / (duration * 12)))
      const monthlyInterest = Math
        .ceil(((this.rtData.riskInterest.risk2 / 100 * amount) / 12))
      this.monthlyCost = repaymentMonthly + monthlyInterest
    })

    /**
     *  Do this properly, we should  only subscribe the whole form. Just the values we need.
     */
    this.masterForm.valueChanges.subscribe({
        next: this.checkSuperMessage
      }
    )

    // The co-applicant status
    this.otherLoansForm.controls.haveCar.valueChanges.subscribe({
      next: () => {
        const carCountControl = this.otherLoansForm.get('carCount')
        if (this.otherLoansForm.controls.haveCar.value) {
          carCountControl?.setValidators(Validators.required)
        } else {
          carCountControl?.clearValidators()
          carCountControl?.reset()
        }
        carCountControl?.updateValueAndValidity()
      }
    })

    /**
     * Set the value for childcare cost to 0 if the applicant has no children.
     */
    this.contactForm.controls.numberOfChildren.valueChanges.subscribe((value: number) => {
      this.otherLoansForm.controls.monthlyChildcareCost
        .setValue(value === 0 ? 0 : this.otherLoansForm.controls.monthlyChildcareCost.value)
      this.makeChildrenArray(value)
    })


    this.loanForm.controls.purposes.valueChanges.subscribe({
      next: () => {

        /**
         * The second item in the 'purposes' array is the purchase of a car, motorcycle or boat.
         * if this is true, a (new) car is added to kalp
         */
        this.newCar = this.loanForm.controls.purposes.value[1]

        /**
         * update validator
         */
        const newCarControl = this.otherLoansForm.controls.newCar
        if (this.newCar) {
          newCarControl?.setValidators(Validators.required)
        } else {
          newCarControl?.clearValidators()
          newCarControl?.reset()
        }
        newCarControl?.updateValueAndValidity()
        // this.otherLoansForm.get('newCar').markAsTouched() // Mark as touched to trigger validation
      }
    })

    const application = this.injectedLocalStorage.getItem(APPLICATION_NAME)


    if (application) {
      const parsedApplication = JSON.parse(application)
      if ((parsedApplication.timeStamp + (60 * 60 * 24 * 1000)) > new Date().getTime()) {

        // In the beginning, we need to update the form with number of children
        this.makeChildrenArray(parsedApplication.contact?.numberOfChildren)
        this.masterForm.patchValue(parsedApplication)
      }
    }
  }

  public apply(): void {
    const waitRef = this.dialog.open(WaitDialogComponent, {
      disableClose: true
    })
    // Reset the error message
    this.errorMessage = ''

    this.loanService.apply(this.sanitizeApplication()).subscribe({
      next: (userRef: string) => {
        waitRef.close()
        this.dialog.open(SafeSignDialogComponent, {
          data: userRef,
          maxHeight: '90vh',
          width: '475px',
          maxWidth: '98%'
        })
          .afterClosed().subscribe({
          next: result => {
            if (result && result.status === 'ok') {
              this.loanService.setToken(result.accessToken)
              return this.router.navigate([`ansok/svar`])
            } else {
              if (result?.status === 'cancel') {
                // User pressed cancel, do nothing
                return null
              }
              // this status would indicate that the sign timed out or was canceled, redirect something else?
              return this.router.navigate([''])
            }
          }
        })
      },
      error: error => {
        // This is the error handler for "apply"
        waitRef.close()
        this.errorMessage = this.errorMessageMap[error.status] ||
          'Aj då! Nu gick något fel, prova igen om en stund.'
      }
    })
  }

  /**
   * Add a loan to the end of the array
   */
  public addLoan(): void {
    this.additionalLoans.push(new FormGroup({
      amount: new FormControl(null, Validators.required),
      type: new FormControl(null, Validators.required),
      solve: new FormControl(null)
    }))
  }

  /**
   * Removes the additional loan based on index
   *
   * @param index - The position base 0
   */
  public removeLoan(index: number): void {
    this.additionalLoans.removeAt(index)
  }

  /**
   * Make sure all important values are set properly this is called
   * Before sending to the backend to make sure we send good
   * values.
   */
  private sanitizeApplication(): any {
    // set spouse to coApplicant, maybe use this in the future, else remove
    // if (this.contactForm.get('isSpouse') && this.coApplicant) {
    //   this.contactForm.get('spouse').setValue(this.contactForm.get('contactData').value[1]?.personNummer)
    // }
    // Save the cleaned up application.
    this.masterForm.controls.version.setValue(version.version)
    this.masterForm.controls.timeStamp.setValue(new Date().getTime())
    const data = this.masterForm.getRawValue()
    this.injectedLocalStorage.setItem(APPLICATION_NAME, JSON.stringify(data))
    // Restructure some fields to make it better for backend.
    // Gather all contact data in one array
    data.applicants = data.contact.contactData
    data.applicants = data.applicants.map((applicant, index) => Object.assign(applicant, data.income.applicants[index]))
    delete data.contact.contactData
    delete data.income
    // Set co-applicant on top level.
    data.coApplicant = data.contact.coApplicant
    delete data.contact.coApplicant
    // Rename to household and clean up
    data.houseHold = data.contact
    delete data.contact
    // Replace loan purposes with texts
    data.loan.purposes = Object.keys(data.loan.purposes)
      .map((key, index) => data.loan.purposes[key] === true ? this.loanPurposes[index] : undefined)
      .filter((p: string | undefined) => p)
    return data
  }

  /**
   * Extracting the form checker functions to avoid complexity
   * warning
   */
  private checkCoApplicant = (checked: boolean) => {
    if (checked) {
      this.contacts.push(this.coApplicantContactData)
      this.applicants.push(this.coApplicantIncomeData)
    } else {
      this.contacts.at(1)?.reset()
      this.applicants.at(1)?.reset()
      this.masterForm.updateValueAndValidity()
      this.contacts.removeAt(1)
      this.applicants.removeAt(1)
    }
  }

  private checkSuperMessage = (val: any) => {
    this.superMessage.loan = val.loan.amount > 200000
    let lowIncome = false
    let wrongOccupation = false
    val.income.applicants.forEach(applicant => {
      if (applicant.income < 15000) {
        lowIncome = lowIncome || true
      }
      if (applicant.occupation === 2) {
        wrongOccupation = wrongOccupation || true
      }
    })
    this.superMessage.income = lowIncome
    this.superMessage.occupation = wrongOccupation
    this.superMessage.coSignedLoan = this.otherLoansForm.controls.coSignedLoan.value != null
    this.superMessage.additionalCosts = this.otherLoansForm.controls.additionalCosts.value != null
    this.superFast = !(
      lowIncome ||
      wrongOccupation ||
      this.superMessage.loan ||
      this.otherLoansForm.controls.coSignedLoan.value ||
      this.otherLoansForm.controls.additionalCosts.value)
  }
}
