// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  loginServiceUrl: 'https://blanco-api-jorgen.lana.sparbankensyd.se/api',
  userServiceUrl: 'https://spb-common-api-jorgen.internal.sparbankensyd.se/service',
  authServiceUrl: 'https://www-test.lana.sparbankensyd.se/service',
  rtUrl: 'https://www-test.lana.sparbankensyd.se/interests',
  domain: 'blanco-api-jorgen.lana.sparbankensyd.se',
  adminDomain: 'https://bl-test.internal.sparbankensyd.se',
}
