<div matDialogTitle>Signera med BankID</div>

<div matDialogContent>
  <p class="text">För att signera behöver du använda ditt BankID. Du väljer om du vill signera på denna enhet eller
    signera från en annan enhet genom att scanna en QR kod.</p>

  <spb-bankid
    [bankIdUrl]="loginServiceUrl"
    (accessToken)="signDialogRef.close({status: 'ok', accessToken: $event})"
    [domain]="domain"
    [userRef]="userRef"
  >
  </spb-bankid>
</div>

<div matDialogActions class="close">
  <button [mat-dialog-close]="undefined" mat-raised-button>Avbryt</button>
</div>
