import {Component, Inject} from '@angular/core'
import {Router} from '@angular/router'
import {WINDOW} from '../../application/window.provider'

@Component({
  selector: 'spb-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {

  /**
   * The constructor
   *
   * @param router - Needed to navigate on menu actions from code.
   * @param injectedWindow - If we navigate of app we need this.
   */
  constructor(
    private router: Router,
    @Inject(WINDOW) private injectedWindow: Window
  ) {
  }

  /**
   * A navigation function that can navigate internally or externally
   *
   * @param link - The place to go, start with https:// to navigate externally.
   */
  public navigate(link: string): void | any {
    if (link.indexOf('https://') !== -1) {
      this.injectedWindow.location.href = link
    } else {
      return this.router.navigate([link])
    }
  }
}
