<div class="holder">
  <div class="breadcrumbs">
    <span tabindex="-1" (keyup.enter)="navigate(-1)" (click)="navigate(-1)" class="link">Sparbanken Syd<span> |  </span> </span>
    <span *ngFor="let link of elements; let idx = index; let lst = last; let fst = first">
      <span *ngIf="!fst" class="last"> /  </span>
      <span tabindex={idx} (keyup.enter)="navigate(idx)" (click)="navigate(idx)" *ngIf="!lst" class="link">{{link}}</span>
      <span *ngIf="lst" class="last">{{link}}</span>
    </span>
  </div>
</div>
