<div>
  <div class="top-holder">
    <div class="top">
      <div tabindex="-1" class="internetbanken"
           (keyup.enter)="navigate('https://internetbank.sparbankensyd.se/')"
           (click)="navigate('https://internetbank.sparbankensyd.se/')">
        <div>
          <em class="material-icons">
            lock_outline
          </em>
        </div>
        <div>
          Internetbanken
        </div>
      </div>
    </div>
  </div>
  <div class="middle-holder">
    <div class="middle">
      <div tabindex="0"
           (keyup.enter)="navigate('https://www.sparbankensyd.se/')"
           (click)="navigate('https://www.sparbankensyd.se/')" class="logo">Sparbanken Syd
      </div>
      <div class="spb-filler"></div>
    </div>
  </div>
</div>
