<spb-breadcrumbs [links]="['https://www.sparbankensyd.se/lana-pengar/']"
                 path="Låna | Privatlån"></spb-breadcrumbs>
<div class="text-holder">
  <h1 class="mat-display-2" i18n="page title|Application loan">Ansökan Privatlån</h1>
  <p i18n="intro|Introduction">Vad roligt att {{plrDuNi.toLowerCase()}} vill ansöka om lån hos oss i Sparbanken Syd!
    Du får svar på din ansökan direkt om du uppfyller kraven nedan och signerar med BankID.
    Vi följer alltid upp din ansökan med en personlig kontakt.</p>
  <p>Du kan ansöka och få svar direkt om du/ni</p>
  <ul>
    <li>är kund i Sparbanken Syd och har internetbanken (är du inte kund ansöker du enkelt om det
      <a href="https://www.sparbankensyd.se/om-sparbanken-syd/om-sparbanken-syd/bli-kund/"
         rel="noopener noreferrer"
         target="_blank">här</a>)
    </li>
    <li>är över 18 år</li>
    <li>inte har några betalningsanmärkningar</li>
    <li>har en årsinkomst på lägst 180 000 kr</li>
    <li>har en tillsvidareanställning eller är pensionär</li>
  </ul>
  <p>Du kan låna mellan 30 000 kr och 200 000 kr och få svar direkt. Om du vill låna mera (max 350 000 kr) kommer din
    ansökan att beredas av en handläggare och du blir kontaktad av banken senast nästa bankdag.</p>
  <p>Kontakta Kundservice på tel <a href="tel:+46 411822000">0411-82 20 00</a> om du har några frågor kring
    ansökningsprocessen.</p>
  <p>När din ansökan är beviljad signerar du dina lånehandlingar digitalt i internetbanken. Lånehandlingarna får du
    senast nästa bankdag och lånet utbetalas samma dag (om du signerat lånehandlingarna före kl. 16.00). I
    internetbanken kan du även ta del av förhandsinformation, allmänna villkor etc.</p>
</div>
<div class="text-holder step-holder">
  <mat-vertical-stepper spbStepperScroll>
    <mat-step [stepControl]="loanForm" label="Sökt lån">
      <div class="mat-h3">Uppgifter om sökt lån</div>
      <p class="lead-text" i18n="lead text|How much to loan">
        Hur mycket vill {{plrDuNi.toLowerCase()}} låna? (30 000 kr - 350 000 kr)</p>
      <form [formGroup]="loanForm">
        <mat-form-field class="standard-full-width margin-top-small">
          <mat-label>Ange lånebelopp</mat-label>
          <label><input
              formControlName="amount"
              inputmode="numeric"
              matInput
              spbFormatNumber
              type="text">
          </label>
        </mat-form-field>
        <p class="lead-text">Under hur lång tid?</p>
        <mat-form-field class="standard-full-width margin-top-small"
        >
          <mat-label>Välj i listan</mat-label>
          <mat-select formControlName="duration"
                      ngDefaultControl>
            <mat-option *ngFor="let duration of range" [value]="duration">
              {{duration}} år
            </mat-option>
          </mat-select>
        </mat-form-field>
        <div class="month-cost">
          <div class="title">Beräknad månadskostnad:</div>
          <div class="amount">{{monthlyCost | number:'1.0-0':'fr'}}
            <span *ngIf="!monthlyCost"> - </span> kr/mån*
          </div>
          <div class="disclaimer">* {{rtData.disclaimer}}
          </div>
        </div>
        <div class="mat-h3" i18n="heading|I want to loan for">{{plrJagVi}} vill låna till</div>
        <div formGroupName="purposes">
          <div *ngFor="let item of purposesArray; let i = index"
               class="standard-full-width">
            <mat-checkbox
                [formControlName]="i"
                [value]="item.control.value"
                color="primary" ngDefaultControl>{{item.key}}</mat-checkbox>
          </div>
        </div>
      </form>
      <div class="buttons head-room">
        <button [disabled]="loanForm.invalid"
                color="primary"
                mat-raised-button matStepperNext>Nästa
        </button>
      </div>
    </mat-step>
    <!-- Step two - Contact info and personnummer -->
    <mat-step [stepControl]="contactForm" label="Sökande och hushåll">
      <form [formGroup]="contactForm">
        <div class="mat-h3">Sökande och hushåll</div>
        <p i18n="lead text|Behandla personuppgifter">
          För att kunna behandla {{plrDinEr}} ansökan och återkomma till {{plrDigEr}} behöver vi e-post och
          telefonnummer.</p>
        <p i18n="link|Personuppgiftslänk">
          <a href="https://www.sparbankensyd.se/om-sparbanken-syd/kundskydd/integritet-och-sekretess-gdpr/">
            Så här tar vi hand om {{plrDinaEra}} personuppgifter</a>.</p>

        <div>
          <mat-checkbox color="primary"
                        formControlName="coApplicant"
                        ngDefaultControl>Är ni flera sökanden?
          </mat-checkbox>
        </div>

        <div>

          <mat-checkbox
              *ngIf="hasCoApplicant"
              color="primary"
              formControlName="applicantsAreSpouses"
              ngDefaultControl>Är ni som tillsammans söker lån gifta, partners eller sambo?
          </mat-checkbox>
        </div>

        <div class="head-room" formArrayName="contactData">
          <div *ngFor="let contact of contacts.controls;index as i" [formGroupName]="i">
            <p *ngIf="i === 0 " class="mat-h4">{{hasCoApplicant ? 'Sökande' : ''}}</p>
            <p *ngIf="i === 1 " class="mat-h4">Medsökande</p>
            <mat-form-field class="standard-full-width">
              <mat-label>Personnummer <span *ngIf="hasCoApplicant && i === 0"> sökande</span>
                <span *ngIf="i === 1"> medsökande</span>
              </mat-label>
              <label>
                <input formControlName="personNummer"
                       matInput
                       spbPersonnummerValidator
                       [otherPersonnummers]="i === 1 ? [mainContactPersonnummer] : [coContactPersonnummer]"
                       type="tel">
              </label>
            </mat-form-field>
            <mat-error class="error-message-holder" *ngIf="contact.get('personNummer')?.errors?.error">{{
                    contact.get('personNummer')?.errors?.isDuplicated ?
                        'Sökande och medsökande kan inte ha samma personnummer'
                        : contact.get('personNummer')?.errors?.error
              }}
            </mat-error>
            <mat-form-field class="standard-full-width">
              <mat-label>För- och efternamn <span *ngIf="hasCoApplicant && i === 0"> sökande</span>
                <span *ngIf="i === 1"> medsökande</span>
              </mat-label>
              <label>
                <input
                    formControlName="name"
                    matInput
                    minlength=5
                    name="name"
                    type="text">
              </label>
            </mat-form-field>
            <mat-form-field class="standard-full-width">
              <mat-label>E-post <span *ngIf="hasCoApplicant && i === 0"> sökande</span>
                <span *ngIf="i === 1">medsökande</span></mat-label>
              <label>
                <input formControlName="email"
                       matInput
                       type="email">
              </label>
            </mat-form-field>
            <mat-form-field class="standard-full-width">
              <mat-label>Telefon <span *ngIf="hasCoApplicant && i === 0"> sökande</span>
                <span *ngIf="i === 1"> medsökande</span></mat-label>
              <label>
                <input formControlName="phone"
                       matInput
                       type="tel">
              </label>
            </mat-form-field>
            <mat-error
                *ngIf="contact.get('phone')?.invalid && (contact.get('phone')?.dirty || contact.get('phone')?.touched)"
                class="error-message-holder">
              Ange ett giltigt telefonnummer
            </mat-error>
          </div>
        </div>

        <p class="lead-text" i18n="label|Number of children">Antal hemmavarande barn under 18 år</p>
        <mat-form-field class="standard-full-width margin-top-small">
          <mat-label>Välj i listan</mat-label>
          <mat-select formControlName="numberOfChildren" ngDefaultControl>
            <mat-option *ngFor="let count of [0,1,2,3,4,5,6]" [value]="count">
              {{count}}
            </mat-option>
          </mat-select>
        </mat-form-field>


        <div formArrayName="children" class="flex">
          <div *ngFor="let child of childrenFormArray; let i = index" [formGroupName]="i">
            <mat-form-field>
              <mat-label>Ålder på barn{{childrenFormArray.length > 1 ? ' ' + (i + 1) : 'et'}}</mat-label>
              <mat-select formControlName="age" ngDefaultControl>
                <mat-option *ngFor="let age of ages" [value]="age">{{ age | number: '1.0-0' }}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>

      </form>
      <div class="buttons">
        <button mat-raised-button matStepperPrevious>Tillbaka</button>
        <button [disabled]="contactForm.invalid"
                color="primary"
                mat-raised-button matStepperNext type="button">Nästa
        </button>
      </div>
    </mat-step>
    <!-- Step three: Income and occupation -->
    <mat-step [stepControl]="incomeForm" label="Inkomster">
      <form [formGroup]="incomeForm">
        <div formArrayName="applicants">
          <div class="mat-h3">Inkomst per månad, före skatt</div>
          <p>Ange inkomster, såsom lön, bidrag och andra regelbundna ersättningar. Ange belopp i kronor
            <strong>före</strong> skatt</p>
          <div *ngFor="let applicant of applicants.controls;let i = index;let len = count"
               [formGroupName]="i">
            <p *ngIf="i === 0 && len > 1" class="mat-h4">Sökande</p>
            <p *ngIf="i === 1" class="mat-h4" [style.margin-top]="'40px'">Medsökande</p>
            <mat-form-field class="standard-full-width">
              <mat-label>Inkomst per månad</mat-label>
              <label>
                <input
                    formControlName="income"
                    inputmode="numeric"
                    spbFormatNumber
                    matInput
                    type="text">
              </label>
            </mat-form-field>
            <div *ngIf="applicant.get('income')?.value > 100000" class="error-message-holder">
              Den angivna inkomsten är väldigt hög. Har du angett <strong>månads</strong>inkomst?
            </div>
            <p class="lead-text">Sysselsättning</p>
            <mat-form-field class="standard-full-width margin-top-small">
              <mat-label>Välj i listan</mat-label>
              <mat-select formControlName="occupation"
                          ngDefaultControl>
                <mat-option *ngFor="let occupation of occupationTypes" [value]="occupation.value">
                  {{occupation.name}}
                </mat-option>
              </mat-select>
            </mat-form-field>

            <ng-container
                *ngIf="applicant.value.occupation === 0 ||
                applicant.value.occupation === 2 ||
                applicant.value.occupation === 3">
              <p class="lead-text">Arbetsgivare</p>
              <mat-form-field class="standard-full-width margin-top-small">
                <mat-label>Ange namnet på arbetsgivare</mat-label>
                <label>
                  <input
                      formControlName="employer"
                      matInput
                      minlength=2
                      type="text"
                  >
                </label>
              </mat-form-field>
            </ng-container>

            <div [style.margin-bottom]="'15px'">
              <mat-checkbox color="primary"
                            formControlName="akassa"
                            ngDefaultControl>
                Markera om {{hasCoApplicant && i > 0 ? 'medsökande' : 'du'}} är medlem i A-kassa
              </mat-checkbox>
            </div>
            <div
                *ngIf="applicant.get('occupation')?.errors &&
                (applicant.get('occupation')?.dirty || applicant.get('occupation')?.touched) ">
              <div *ngIf="applicant.get('occupation')?.errors?.pattern" class="error-message-holder">
                Vi kan bara bevilja lån automatiskt om du är tillsvidareanställd eller pensionär.
              </div>
            </div>

            <div [style.margin-bottom]="'40px'" *ngIf="!contactForm.controls.applicantsAreSpouses.value">
              <p class="lead-text"
                 i18n="label|Are you spouses?">
                Är {{hasCoApplicant && i > 0 ? 'medsökande' : 'du'}} gift, har en registrerad partner eller är sambo? </p>
              <mat-form-field class="standard-full-width margin-top-small">
                <mat-label>Välj i listan</mat-label>
                <mat-select formControlName="isSpouse" ngDefaultControl
                            (selectionChange)="onSpouseSelectionChange($event.value, applicant)">
                  <mat-option [value]="''" disabled>Välj i listan</mat-option>
                  <mat-option [value]=true>Ja</mat-option>
                  <mat-option [value]="false">Nej</mat-option>
                </mat-select>
                <mat-error *ngIf="applicant.get('isSpouse')?.hasError('required')">
                  Du måste välja ett alternativ.
                </mat-error>
              </mat-form-field>

              <div *ngIf="applicant.get('isSpouse')?.value">
                <mat-form-field class="standard-full-width">
                  <mat-label>Ange dennes personnummer
                  </mat-label>
                  <label>
                    <input formControlName="spouse"
                           matInput
                           spbPersonnummerValidator
                           [otherPersonnummers]="i > 0 ? [coContactPersonnummer] : [mainContactPersonnummer]"
                           type="tel">
                  </label>
                  <mat-error>{{
                          applicant.get('spouse')?.errors?.isDuplicated ?
                              'Personnumret kan inte vara samma som '
                              + (i > 0 ? 'medsökande' : 'sökande') + ' i föregående steg'
                              : applicant.get('spouse')?.errors?.error
                    }}
                  </mat-error>
                </mat-form-field>
              </div>

            </div>

          </div>
        </div>
      </form>
      <div class="buttons">
        <button mat-raised-button matStepperPrevious type="button">Tillbaka</button>
        <button [disabled]="incomeForm.invalid"
                color="primary"
                mat-raised-button matStepperNext type="button">Nästa
        </button>
      </div>
    </mat-step>
    <!-- Step four: Living, primary and additional -->
    <mat-step [stepControl]="livingForm" label="Boende">
      <form [formGroup]="livingForm">
        <div class="mat-h3">Boendeuppgifter</div>
        <p class="lead-text">Typ av bostad</p>
        <div [formGroup]="primaryLiving">
          <mat-form-field class="standard-full-width margin-top-small">
            <mat-label>Välj i listan</mat-label>
            <mat-select formControlName="type"
                        ngDefaultControl>
              <mat-option *ngFor="let property of propertyTypes" [value]="property.type">
                {{property.name}}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <div *ngIf="primaryLiving.controls.type.value !== null && primaryLiving.controls.type.value !== HYRESRATT">
            <p class="lead-text">Summa bolån</p>
            <mat-form-field class="standard-full-width margin-top-small">
              <mat-label>Summa bolån</mat-label>
              <label>
                <input formControlName="loan"
                       inputmode="numeric"
                       matInput
                       spbFormatNumber>
              </label>
            </mat-form-field>
          </div>
          <div *ngIf="primaryLiving.controls.type.value !== null && primaryLiving.controls.type.value === VILLA">
            <div *ngIf="primaryLiving.controls.loan.value === '0'" class="super-fast"> Du har inte angett
              några bolån
              – är det korrekt? Om du bor i villa som du inte själv äger skall du välja ”Hyresrätt” som
              typ av bostad
              och ange hyra.
            </div>
          </div>
          <div *ngIf="primaryLiving.controls.type.value === HYRESRATT">
            <mat-form-field
                class="standard-full-width margin-top-small">
              <mat-label>Hyra</mat-label>
              <input formControlName="rent"
                     inputmode="numeric" matInput spbFormatNumber/>
            </mat-form-field>
          </div>
          <div *ngIf="primaryLiving.controls.type.value === BOSTADSRATT">
            <p class="lead-text">Avgift kr/mån</p>
            <mat-form-field
                class="standard-full-width margin-top-small">
              <mat-label>Avgift</mat-label>
              <label>
                <input formControlName="fee"
                       inputmode="numeric"
                       matInput
                       spbFormatNumber>
              </label>
            </mat-form-field>
          </div>
        </div>
        <p class="lead-text" i18n="heading|Other living">Har {{plrDuNi.toLowerCase()}} andra boenden?
        </p>
        <mat-form-field [formGroup]="secondaryLiving" class="standard-full-width margin-top-small">
          <mat-label>Välj i listan</mat-label>
          <mat-select formControlName="type" ngDefaultControl>
            <mat-option *ngFor="let old of oldPropertyTypes" [value]="old.type">
              {{old.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <div *ngIf="secondaryLivingType !== null && secondaryLivingType !== -1" [formGroup]="secondaryLiving">
          <div *ngIf="secondaryLivingType === BOSTADSRATT">
            <p class="lead-text">Avgift kronor/månad</p>
            <mat-form-field class="standard-full-width margin-top-small">
              <mat-label>Avgift kronor/månad</mat-label>
              <label>
                <input
                    formControlName="fee"
                    inputmode="numeric"
                    matInput
                    spbFormatNumber
                >
              </label>
            </mat-form-field>
          </div>
          <div *ngIf="secondaryLivingType === HYRESRATT">
            <p class="lead-text">Hyra kronor/månad</p>
            <mat-form-field class="standard-full-width margin-top-small">
              <mat-label>Hyra kronor/månad</mat-label>
              <label>
                <input
                    formControlName="rent"
                    inputmode="numeric"
                    matInput
                    spbFormatNumber>
              </label>
            </mat-form-field>
          </div>
          <div
              *ngIf="secondaryLivingType !== null && secondaryLivingType !== HYRESRATT && secondaryLivingType !== -1">
            <p class="lead-text">Summa bolån</p>
            <mat-form-field class="standard-full-width margin-top-small">
              <mat-label>Summa bolån</mat-label>
              <label>
                <input
                    formControlName="loan"
                    inputmode="numeric"
                    matInput
                    spbFormatNumber>
              </label>
            </mat-form-field>
          </div>
        </div>
      </form>

      <div class="buttons">
        <button mat-raised-button matStepperPrevious>Tillbaka</button>
        <button [disabled]="livingForm.invalid"
                color="primary"
                mat-raised-button matStepperNext>Nästa
        </button>
      </div>
    </mat-step>
    <!-- Step five: Additional loans, including study loans -->
    <mat-step [stepControl]="otherLoansForm" label="Övriga kostnader">
      <form [formGroup]="otherLoansForm">
        <div class="mat-h3">Övriga kostnader</div>

        <p class="lead-text">Har {{plrDuNi.toLowerCase()}} studielån? Ange hushållets kostnad i
          kronor<strong>/månad</strong>
        </p>
        <mat-form-field class="standard-full-width margin-top-small">
          <mat-label>Kostnad studielån kronor/månad</mat-label>
          <label>
            <input formControlName="studyLoan"
                   inputmode="numeric"
                   matInput
                   spbFormatNumber>
          </label>
          <mat-error *ngIf="otherLoansForm.get('studyLoan')?.hasError('max')">
            Summan verkar vara för hög. Ange kostnad i kronor/månad.
          </mat-error>
        </mat-form-field>


        <div *ngIf="contactForm.controls.numberOfChildren.value > 0">
          <p class="lead-text">Har {{plrDuNi.toLowerCase()}} en kostnad för barnomsorg? Ange hushållets
            kostnad i
            kronor<strong>/månad</strong></p>

          <mat-form-field class="standard-full-width margin-top-small">
            <mat-label>Kostnad barnomsorg kronor/månad</mat-label>
            <label><input
                formControlName="monthlyChildcareCost"
                inputmode="numeric"
                matInput
                spbFormatNumber>
            </label>
            <mat-error *ngIf="otherLoansForm.get('monthlyChildcareCost')?.hasError('max')">
              Summan verkar vara för hög. Ange kostnad i kronor/månad.
            </mat-error>
          </mat-form-field>
        </div>

        <p class="lead-text"
           i18n="label|Do you have additional costs that we need to consider in a credit assessment">
          Har {{plrDuNi.toLowerCase()}} några ytterligare kostnader som vi behöver ta hänsyn till i en
          kreditprövning,
          t.ex. kontraktsbaserade kostnader (såsom hyra av övernattningslägenhet, garageplats, magasinering),
          extra
          kostnadskrävande hobby, etc.?</p>
        <mat-form-field class="standard-full-width margin-top-small">
          <mat-label>Välj i listan</mat-label>
          <mat-select formControlName="additionalCosts" ngDefaultControl>
            <mat-option [value]="''" disabled>Välj i listan</mat-option>
            <mat-option [value]=true>Ja</mat-option>
            <mat-option [value]="false">Nej</mat-option>
          </mat-select>
          <mat-error *ngIf="otherLoansForm.get('additionalCosts')?.hasError('required')">
            Du måste välja ett alternativ.
          </mat-error>
        </mat-form-field>

        <p class="lead-text" i18n="label|Have you co-signed a loan for someone else">
          Har {{plrDuNi.toLowerCase()}} gått
          i borgen för någon annans lån?</p>
        <mat-form-field class="standard-full-width margin-top-small">
          <mat-label>Välj i listan</mat-label>
          <mat-select formControlName="coSignedLoan" ngDefaultControl>
            <mat-option [value]="''" disabled>Välj i listan</mat-option>
            <mat-option [value]=true>Ja</mat-option>
            <mat-option [value]="false">Nej</mat-option>
          </mat-select>
          <mat-error *ngIf="otherLoansForm.get('coSignedLoan')?.hasError('required')">
            Du måste välja ett alternativ.
          </mat-error>
        </mat-form-field>

        <div class="mat-h3">Bil</div>

        <div *ngIf="newCar">
          <p class="lead-text" i18n="label|Adding car to household affects credit">{{plrDuNi}} har angett att
            syftet
            med {{plrDinEr.toLowerCase()}} kreditansökan är ”Köp av bil, MC eller båt”. Avser köpet bil och
            {{plrDuNi.toLowerCase()}} således utökar antalet bilar i hushållet med detta köp?</p>
          <mat-form-field class="standard-full-width margin-top-small">
            <mat-label>Välj i listan</mat-label>
            <mat-select formControlName="newCar" ngDefaultControl>
              <mat-option [value]="''" disabled>Välj i listan</mat-option>
              <mat-option [value]=true>Ja</mat-option>
              <mat-option [value]="false">Nej</mat-option>
            </mat-select>
          </mat-form-field>
        </div>


        <p class="lead-text"
           i18n="label|Do you have a car">{{newCar ? 'Äger ' + plrDuNi.toLowerCase() + ' redan bil?' : 'Äger ' + plrDuNi.toLowerCase() + ' bil?'}}</p>
        <mat-form-field class="standard-full-width margin-top-small">
          <mat-label>Välj i listan</mat-label>
          <mat-select formControlName="haveCar" ngDefaultControl>
            <mat-option [value]="''" disabled>Välj i listan</mat-option>
            <mat-option [value]=true>Ja</mat-option>
            <mat-option [value]="false">Nej</mat-option>
          </mat-select>
          <mat-error *ngIf="otherLoansForm.get('haveCar')?.hasError('required')">
            Du måste välja ett alternativ.
          </mat-error>
        </mat-form-field>

        <div *ngIf="otherLoansForm.controls.haveCar.value">
          <p class="lead-text" i18n="label|Enter the number of cars you own">Ange antal
            bilar {{plrDuNi.toLowerCase()}}
            äger</p>
          <mat-form-field class="standard-full-width margin-top-small">
            <mat-label>Välj i listan</mat-label>
            <mat-select formControlName="carCount" ngDefaultControl>
              <mat-option [value]="''" disabled>Välj ett alternativ</mat-option>
              <mat-option *ngFor="let carCount of [1, 2, 3]" [value]="carCount">{{carCount}}</mat-option>
            </mat-select>
            <mat-error *ngIf="otherLoansForm.get('carCount')?.hasError('required')">
              Du måste välja ett alternativ.
            </mat-error>
          </mat-form-field>
        </div>

        <p class="lead-text" i18n="label|Do you lease a car privately">Privatleasar {{plrDuNi.toLowerCase()}}
          bil?</p>
        <mat-form-field class="standard-full-width margin-top-small">
          <mat-label>Välj i listan</mat-label>
          <mat-select formControlName="privateLeaseCar" ngDefaultControl>
            <mat-option [value]="''" disabled>Välj i listan</mat-option>
            <mat-option [value]=true>Ja</mat-option>
            <mat-option [value]="false">Nej</mat-option>
          </mat-select>
          <mat-error *ngIf="otherLoansForm.get('privateLeaseCar')?.hasError('required')">
            Du måste välja ett alternativ.
          </mat-error>
        </mat-form-field>

        <div *ngIf="otherLoansForm.get('privateLeaseCar')?.value === true">
          <p class="lead-text">Ange {{plrDinaEra.toLowerCase()}} faktiska kostnader för privatleasad bil. Ange
            hushållets kostnad i
            kronor<strong>/månad</strong></p>
          <mat-form-field class="standard-full-width margin-top-small">
            <mat-label>Kostnad privatleasing bil kronor/månad</mat-label>
            <label><input
                formControlName="monthlyPrivateLeaseCarCost"
                inputmode="numeric"
                matInput
                spbFormatNumber>
            </label>
          </mat-form-field>
        </div>


        <div class="mat-h3">Övriga privatlån och krediter</div>

        <mat-checkbox color="primary"
                      formControlName="otherLoans"
                      ngDefaultControl>
          Har {{plrDuNi.toLowerCase()}} andra privatlån och krediter, t ex billån och krediter kopplade
          till kort? Ange alla {{plrDinaEra}} privatlån.
        </mat-checkbox>
        <div *ngIf="additionalLoans" formArrayName="loans">
          <div *ngFor="let loan of additionalLoans.controls; let i = index;let last = last"
               [formGroupName]="i">
            <p class="lead-text head-room">Lånebelopp i kronor</p>
            <mat-form-field class="standard-full-width margin-top-small">
              <mat-label>Lånebelopp kronor</mat-label>
              <label>
                <input formControlName="amount"
                       inputmode="numeric"
                       matInput
                       spbFormatNumber
                       type="text">
              </label>
            </mat-form-field>
            <mat-form-field class="standard-full-width">
              <mat-label>Typ av lån</mat-label>
              <mat-select formControlName="type" ngDefaultControl>
                <mat-option *ngFor="let type of additionalLoanTypes" [value]="type.type">
                  {{type.name}}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <div class="head-room">
              <mat-checkbox color="primary"
                            formControlName="solve"
                            ngDefaultControl>
                {{plrJagVi}} vill samla {{plrMinaVara.toLowerCase()}} privatlån hos Sparbanken Syd och
                kommer att
                lösa detta privatlån.
              </mat-checkbox>
            </div>
            <div class="loan-buttons head-room">
              <a tabindex={i} (keyup.enter)="removeLoan(i)" (click)="removeLoan(i)" *ngIf="i > 0">Ta bort rad</a>
              <a tabindex={i+1} (keyup.enter)="addLoan()" (click)="addLoan()" *ngIf="last">Lägg till rad</a>
            </div>
          </div>
        </div>
        <p></p>
      </form>
      <div class="buttons head-room">
        <button mat-raised-button matStepperPrevious>Tillbaka</button>
        <button [disabled]="otherLoansForm.invalid" color="primary" mat-raised-button matStepperNext>Nästa
        </button>
      </div>
    </mat-step>
    <!-- Step six: Accept terms and submit -->
    <mat-step [stepControl]="masterForm" label="Ansök">
      <div>
        <div *ngIf="!superMessage.loan && !superMessage.income">
          <p>När ansökan signeras nedan tar vi en kreditupplysning och gör en bedömning
            av {{plrDinEr.toLowerCase()}}
            ekonomi. Om {{plrDinEr.toLowerCase()}} ansökan
            blir godkänd visas {{plrDinEr}} individuella räntesats.</p>
          <p>Lånehandlingar får {{plrDuNi.toLowerCase()}} senast nästa bankdag och lånet utbetalas samma dag
            (om {{plrDuNi.toLowerCase()}} signerat lånehandlingarna
            före kl. 16.00). {{plrDuNi}} får ett sms och ett meddelande i internetbanken så snart
            lånehandlingarna
            finns
            på
            plats.
          </p>
        </div>
        <div *ngIf="superFast; else manual">
          <div class="mat-h3">Ansök automatiskt med BankID</div>
        </div>
        <ng-template #manual>
          <div class="mat-h3">Ansök till handläggare med BankID</div>
          <div class="super-fast">
            <div *ngIf="superMessage.coSignedLoan">Baserat på att {{plrDuNi.toLowerCase()}} har angett att
              du har gått i
              borgen för någon annan kan vi inte behandla {{plrDinEr}} ansökan automatiskt.
            </div>
            <div *ngIf="superMessage.additionalCosts">Baserat på att {{plrDuNi.toLowerCase()}} har angett
              att du har
              ytterligare kostnader som vi behöver ta hänsyn till i en kreditprövning kan vi inte
              behandla {{plrDinEr}}
              ansökan automatiskt.
            </div>
            <div *ngIf="superMessage.loan">Baserat på att {{plrDuNi.toLowerCase()}} ansöker om
              mer än 200 000 kr kan
              vi inte behandla {{plrDinEr}} ansökan automatiskt.
            </div>
            <div *ngIf="superMessage.income">Baserat på att du angett att {{plrDinEr}}
              årsinkomst
              understiger 180 000 kr kan vi inte behandla {{plrDinEr}} ansökan automatiskt
            </div>
            <div *ngIf="superMessage.occupation">Baserat på att {{plrDuNi.toLowerCase()}} har
              angett att {{plrDuNi.toLowerCase()}} är
              visstidsanställd kan vi inte behandla {{plrDinEr}} ansökan automatiskt.
            </div>
            <div>{{plrDuNi}} kan gå vidare genom att skicka {{plrDinEr}} ansökan till en rådgivare nedan.
            </div>
          </div>
        </ng-template>
        <div>
          <p>{{plrJagVi}} försäkrar att de uppgifter som lämnats är riktiga och fullständiga. {{plrJagVi}} är
            också
            <span *ngIf="hasCoApplicant">medvetna</span><span *ngIf="!hasCoApplicant">medveten</span> om att
            banken
            kan kontakta <span *ngIf="hasCoApplicant">oss</span><span *ngIf="!hasCoApplicant">mig</span> för att
            inhämta
            ytterligare uppgifter.</p>
          <p>{{plrJagVi}} godkänner att ansökan kommer att genomgå sedvanlig kreditprövning, varvid en
            kreditupplysning kommer att tas.</p>
          <p *ngIf="superFast; else manualPromo">{{plrMinVar}} ansökan kommer att bedömas automatiskt.</p>
          <ng-template #manualPromo>
            {{plrMinVar}} ansökan kommer att behandlas av en rådgivare och {{plrJagVi.toLowerCase()}} kommer
            att bli
            kontaktad inom 24 timmar (under kontorstid).
          </ng-template>
        </div>
        <form [formGroup]="masterForm">
          <div class="head-room">
            <mat-checkbox
                color="primary"
                formControlName="terms"
                ngDefaultControl>
              <span>{{plrJagVi}} är införstådd<span *ngIf="hasCoApplicant">a</span> med och godkänner ovanstående information och villkor.</span>
            </mat-checkbox>
          </div>
        </form>
        <div>
          <button
              class="margin-top-small"
              (click)="apply()"
              [disabled]="masterForm.invalid"
              color="primary"
              mat-raised-button
          >Signera med BankID
          </button>
          <div *ngIf="errorMessage" class="spb-error">{{errorMessage}}</div>
        </div>
      </div>
    </mat-step>
  </mat-vertical-stepper>
</div>
