/* src/app/common/breadcrumbs/breadcrumbs.component.scss */
.holder {
  background-color: #f3f2f1;
  border-top: 1px #e6e5e4 solid;
  margin: 0 auto;
  width: 100%;
}
.breadcrumbs {
  font-size: 18px;
  line-height: 72px;
  margin: 0 auto;
  max-width: 1170px;
  padding-left: 60px;
}
.link {
  color: #626262;
  font-weight: 600;
}
.last {
  color: #626262;
  font-size: 16px;
}
.link:hover {
  cursor: pointer;
}
@media screen and (max-width: 766px) {
  .breadcrumbs {
    display: none;
  }
}
/*# sourceMappingURL=data:application/json;base64,ewogICJ2ZXJzaW9uIjogMywKICAic291cmNlcyI6IFsic3JjL2FwcC9jb21tb24vYnJlYWRjcnVtYnMvYnJlYWRjcnVtYnMuY29tcG9uZW50LnNjc3MiXSwKICAic291cmNlc0NvbnRlbnQiOiBbIkB1c2UgJ0BzcGFyYmFua2VuLXN5ZC9zcGFyYmFua2VuLXN5ZC10aGVtZS92YXJpYWJsZXMnIGFzIHZhcjtcblxuJHNwYi1tYXgtd2lkdGg6IDExNzBweDtcblxuLmhvbGRlciB7XG4gIGJhY2tncm91bmQtY29sb3I6ICNmM2YyZjE7XG4gIGJvcmRlci10b3A6IDFweCAjZTZlNWU0IHNvbGlkO1xuICBtYXJnaW46IDAgYXV0bztcbiAgd2lkdGg6IDEwMCU7XG59XG5cbi5icmVhZGNydW1icyB7XG4gIGZvbnQtc2l6ZTogMThweDtcbiAgbGluZS1oZWlnaHQ6IDcycHg7XG4gIG1hcmdpbjogMCBhdXRvO1xuICBtYXgtd2lkdGg6ICRzcGItbWF4LXdpZHRoO1xuICBwYWRkaW5nLWxlZnQ6IDYwcHg7XG59XG5cbi5saW5rIHtcbiAgY29sb3I6ICM2MjYyNjI7XG4gIGZvbnQtd2VpZ2h0OiA2MDA7XG59XG5cbi5sYXN0IHtcbiAgY29sb3I6ICM2MjYyNjI7XG4gIGZvbnQtc2l6ZTogMTZweDtcbn1cblxuLmxpbms6aG92ZXIge1xuICBjdXJzb3I6IHBvaW50ZXI7XG59XG5cbkBtZWRpYSBzY3JlZW4gYW5kIChtYXgtd2lkdGg6IHZhci4kc3BiLXNtYWxsKSB7XG4gIC5icmVhZGNydW1icyB7XG4gICAgZGlzcGxheTogbm9uZTtcbiAgfVxufVxuIl0sCiAgIm1hcHBpbmdzIjogIjtBQUlBLENBQUE7QUFDRSxvQkFBQTtBQUNBLGNBQUEsSUFBQSxRQUFBO0FBQ0EsVUFBQSxFQUFBO0FBQ0EsU0FBQTs7QUFHRixDQUFBO0FBQ0UsYUFBQTtBQUNBLGVBQUE7QUFDQSxVQUFBLEVBQUE7QUFDQSxhQWJjO0FBY2QsZ0JBQUE7O0FBR0YsQ0FBQTtBQUNFLFNBQUE7QUFDQSxlQUFBOztBQUdGLENBQUE7QUFDRSxTQUFBO0FBQ0EsYUFBQTs7QUFHRixDQVZBLElBVUE7QUFDRSxVQUFBOztBQUdGLE9BQUEsT0FBQSxJQUFBLENBQUEsU0FBQSxFQUFBO0FBQ0UsR0F2QkY7QUF3QkksYUFBQTs7OyIsCiAgIm5hbWVzIjogW10KfQo= */
