import {Component, Inject} from '@angular/core'
import {environment} from '../../../../environments/environment'
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog'

@Component({
  selector: 'spb-safe-sign-dialog',
  templateUrl: './safe-sign-dialog.component.html',
  styleUrls: ['./safe-sign-dialog.component.scss']
})

export class SafeSignDialogComponent {
  /**
   * The URL and domain BankID shall use.
   */
  public loginServiceUrl = environment.loginServiceUrl

  public domain = environment.domain

  constructor(
    public signDialogRef: MatDialogRef<SafeSignDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public userRef: string
  ) {
  }
}
