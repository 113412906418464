{
  "name": "loan-frontend",
  "version": "2.3.1-develop.6",
  "scripts": {
    "install": "cp ./package.json ./src/assets",
    "ng": "ng",
    "start": "ng serve --ssl --host localhost.sparbanken.me",
    "build": "./scripts/version.sh && ng build",
    "test": "ng test --watch=false --code-coverage",
    "watch": "ng test",
    "lint": "ng lint",
    "style-lint": "stylelint  \"src/**/*.scss\" --fix"
  },
  "private": true,
  "dependencies": {
    "@angular/animations": "^17.0.6",
    "@angular/common": "^17.0.6",
    "@angular/compiler": "^17.0.6",
    "@angular/core": "^17.0.6",
    "@angular/forms": "^17.0.6",
    "@angular/localize": "^17.0.6",
    "@angular/platform-browser": "^17.0.6",
    "@angular/platform-browser-dynamic": "^17.0.6",
    "@angular/router": "^17.0.6",
    "core-js": "^3.34.0",
    "rxjs": "~7.8.1",
    "tslib": "^2.6.2",
    "zone.js": "~0.14.0"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^17.0.6",
    "@angular-eslint/builder": "17.1.1",
    "@angular-eslint/eslint-plugin": "17.1.1",
    "@angular-eslint/eslint-plugin-template": "17.1.1",
    "@angular-eslint/schematics": "17.1.1",
    "@angular-eslint/template-parser": "17.1.1",
    "@angular/cdk": "^17.0.2",
    "@angular/cli": "^17.0.6",
    "@angular/compiler-cli": "^17.0.6",
    "@angular/language-service": "^17.0.6",
    "@angular/material": "^17.0.2",
    "@sparbanken-syd/personnummer": "^1.2.2",
    "@sparbanken-syd/sparbanken-syd-bankid": "^17.0.0",
    "@sparbanken-syd/sparbanken-syd-qr": "^17.0.1",
    "@sparbanken-syd/sparbanken-syd-theme": "^17.0.2",
    "@types/jasmine": "~5.1.4",
    "@types/jasminewd2": "~2.0.11",
    "@types/node": "^20.10.4",
    "@typescript-eslint/eslint-plugin": "^6.10.0",
    "@typescript-eslint/parser": "^6.10.0",
    "eslint": "^8.55.0",
    "eslint-plugin-import": "2.29.0",
    "eslint-plugin-jsdoc": "^46.8.1",
    "eslint-plugin-prefer-arrow": "^1.2.3",
    "jasmine-core": "~5.1.1",
    "karma": "~6.4.2",
    "karma-chrome-launcher": "~3.2.0",
    "karma-coverage": "~2.2.1",
    "karma-jasmine": "~5.1.0",
    "karma-jasmine-html-reporter": "~2.1.0",
    "postcss": "^8.4.32",
    "postcss-scss": "^4.0.8",
    "stylelint": "^15.10.3",
    "stylelint-config-standard-scss": "^11.0.0",
    "stylelint-scss": "^5.2.1",
    "typescript": "5.2.2"
  }
}
